import React, { useState, useEffect } from 'react';
import { EyeOutlined, ExportOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { Outlet, useNavigate } from "react-router-dom";
import './productcard.css';
import { useSelector } from "react-redux";

const { Option } = Select;

const defaultCurrency = { symbol: "Rs:", price: 1 }

const ProductCard = ({ img, title, prices = [], id }) => { // Default value for prices is an empty array
  const navigate = useNavigate();
  const selectedCurrency = useSelector((state) => state.currency.currency ? state.currency.currency : defaultCurrency);


  const [selectedUnit, setSelectedUnit] = useState(prices[0]?.unit || ''); // Initialize with the first unit if available

  const handleProductSingleNavigation = () => {
    navigate(`/product/${id}`);
  };

  const handleUnitChange = (value) => {
    setSelectedUnit(value);
  };

  return (
    <div className='cs-pc-container'>
      <img className='cs-w-100 cs-pc-image cs-pointer' onClick={handleProductSingleNavigation} src={img} alt="" />
      <div className="cs-pc-middle">
        <div className="cs-pc-img-btn cs-dis-flex">
          <EyeOutlined className='cs-pointer' />
          <ExportOutlined onClick={handleProductSingleNavigation} className='cs-lm-15 cs-pointer' />
        </div>
      </div>

      <div className='cs-txt-pc cs-hrz-center cs-tm-10'>
        {title}
      </div>

      <div className='cs-hrz-center cs-tm-10'>
        <Select value={selectedUnit} style={{ width: 120 }} onChange={handleUnitChange}>
          {prices.map((priceItem, index) => (
            <Option key={index} value={priceItem.unit}>{priceItem.unit}</Option>
          ))}
        </Select>
      </div>

      <div className='cs-hrz-center cs-tm-10'>
        {/* <div className='cs-pd-mrp'>
          MRP: ${mrp}
        </div> */}
        {prices.length > 0 && (
          <div className='cs-pd-pricing cs-lm-10'>
            Price: {selectedCurrency.symbol} {prices.find(priceItem => priceItem.unit === selectedUnit)?.price / (+selectedCurrency.price)}
          </div>
        )}
      </div>

      <div className='cs-pc-footer'></div>
    </div>
  );
};

export default ProductCard;
