import axios from "axios";
import { BASE_URL } from "../common/server";

export const fetchBlogs = async () => {
  try {
    const response = await axios.get(BASE_URL + "/blogs");
    return response.data;
  } catch (error) {
    throw error; // You can handle errors in your component
  }
};
