import React from 'react'
import { Row, Col } from 'antd'
import HomeSlider from '../../components/slider/Slider'
import FeatureCard from '../../components/feature-card/FeatureCard'
import CircleBtn from '../../components/circle-btn/CircleBtn'
import ProductCard from '../../components/product-card/ProductCard'
import Testimonials from '../../components/testimonials/Testimonials'
import { useQuery } from 'react-query';
import { fetchProducts } from '../../services/product.service'
import { Fade, Slide, Reveal } from "react-awesome-reveal";

import slider1 from '../../static/assets/slider-image/home1-slide1.jpg'
import slider2 from '../../static/assets/slider-image/home1-slide2.jpg'
import fc1 from '../../static/assets/feature-card/fc-1.jpg'
import fc2 from '../../static/assets/feature-card/fc-2.jpg'
import fc3 from '../../static/assets/feature-card/fc-3.jpg'
import cb1 from '../../static/assets/circle-btn/cb-1.jpg'
import cb2 from '../../static/assets/circle-btn/cb-2.jpg'
import cb3 from '../../static/assets/circle-btn/cb-3.jpg'
// import pi1 from '../../static/assets/product-img/pi1.jpg'
// import pi2 from '../../static/assets/product-img/pi2.jpg'
// import pi3 from '../../static/assets/product-img/pi3.jpg'
// import pi4 from '../../static/assets/product-img/pi4.jpg'
// import pi5 from '../../static/assets/product-img/pi5.jpg'
import testi from '../../static/assets/static-img/testi_1370x385.jpg'
import testi1 from '../../static/assets/static-img/image_1_303x.avif'
import testi2 from '../../static/assets/static-img/image_2_303x.avif'
import Services from '../../components/services/Services'
import { LongerCaching } from '../../common/config'
import { BASE_URL_ASSETS } from '../../common/server'
import BlogCards from '../../components/blog-cards/BlogCards'
import BlogSlider from '../../components/blog-slider/BlogSlider'
import { fetchBlogs } from '../../services/blog.service'
import Spinner from '../../components/spinner/Spinner'


const Home = () => {
  const SLIDER_DATA = [{ src: slider1, title: "Grab The Latest Offers Now", subTitle: "The Best Grocery Available" }, { src: slider2, title: "Offering Best Quality In Market", subTitle: "Best Price With Best Quality" }]

  const FC_DATA = [{ src: fc1, title: "Save 30% Off", featureTxt: "Spicy Test" },
  { src: fc2, title: "Best In Market", featureTxt: "Best In Test" },
  { src: fc3, title: "Best Sellers", featureTxt: "Healthy" }]

  const CB_DATA = [{ src: cb1, title: "Save 30% Off" },
  { src: cb2, title: "Best In Market", },
  { src: cb3, title: "Best Sellers", }]

  // const PI_DATA = [{ src: pi1, sale: false, price: "78.00", mrp: "100.00", title: "Mirchi powder" },
  // { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  // { src: pi4, sale: false, price: "78.00", mrp: "100.00", title: "Product 4", },
  // { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  // { src: pi3, sale: true, price: "78.00", mrp: "100.00", title: "Product 3", },
  // { src: pi4, sale: false, price: "78.00", mrp: "100.00", title: "Product 4", },
  // { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  // { src: pi5, sale: true, price: "78.00", mrp: "100.00", title: "Product 5", },
  // { src: pi3, sale: true, price: "78.00", mrp: "100.00", title: "Product 3", },
  // { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  // { src: pi5, sale: true, price: "78.00", mrp: "100.00", title: "Product 5", },
  // { src: pi3, sale: true, price: "78.00", mrp: "100.00", title: "Product 3", },
  // ]

  const TESTI_DATA = [
    { src: testi1, name: "Mark embrok", review: "Absolutely blown away by the quality and flavor of the spices from [Spice Brand]. As a passionate home cook, I've tried numerous spice brands, but none come close to the richness and freshness I found in these products. From the vibrant color to the intense aroma, every spice I've used has elevated my dishes to a whole new level. My kitchen feels incomplete without [Spice Brand] spices now. Highly recommended!" },
    { src: testi2, name: "Jack", review: "I stumbled upon [Spice Brand] while searching for high-quality spices online, and I'm so glad I did! The moment I opened the package, I could tell these were no ordinary spices. The packaging itself exuded freshness and authenticity. But it was the taste that truly amazed me. Each spice blend is perfectly balanced, adding depth and complexity to my cooking. Whether it's a simple weekday meal or a special occasion feast, [Spice Brand] spices have become my secret ingredient for creating unforgettable dishes. Thank you for bringing such exceptional flavors into my kitchen!" }
  ]

  // const TOP_PRODUCTS_DATA = [{ src: pi1, sale: false, price: "78.00", mrp: "100.00", title: "Mirchi powder" },
  // { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  // { src: pi4, sale: false, price: "78.00", mrp: "100.00", title: "Product 4", },
  // { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  // { src: pi5, sale: true, price: "78.00", mrp: "100.00", title: "Product 5", },
  // { src: pi3, sale: true, price: "78.00", mrp: "100.00", title: "Product 3", },
  // ]

  /**
  * @api_calls
  */

  // Define a query for rescue type data
  const { data: fetchProductsData, isLoading: fetchProductsLoading, isError: fetchProductsError, refetch: refetchfetchProducts } = useQuery(
    'fetchProductsData',
    () => fetchProducts(),
    {
      ...LongerCaching,
    }
  );

  // Define a query for rescue type data
  const { data: fetchBlogData, isLoading: fetchBlogLoading, isError: fetchBlogError, refetch: refetchfetchBlog } = useQuery(
    'fetchBlogData',
    () => fetchBlogs(),
    {
      ...LongerCaching,
    }
  );


  return (
    !fetchBlogLoading && !fetchProductsLoading ?
      <div>
        <Reveal triggerOnce>
          <HomeSlider data={SLIDER_DATA} />
        </Reveal>

        <Reveal triggerOnce>
          <Row gutter={[40, 40]} className='cs-ml-25 cs-mr-25 cs-tm-40'>
            {FC_DATA.map((item, index) => {
              return (
                <Col xs={24} sm={24} xl={8}>
                  <FeatureCard key={index} image={item.src}
                    title={item.title} featureTxt={item.featureTxt} />
                </Col>
              )
            })}
          </Row>
        </Reveal>

        <Slide triggerOnce direction='left'>
          <div className='cs-ml-25 cs-mr-25 cs-tm-40'>
            <div className='cs-dancing-font cs-fs-25 cs-hrz-center cs-fw-700'>
              Exclusive Products
            </div>
          </div>
        </Slide>

        <Slide triggerOnce direction='right'>
          <div className='cs-ml-25 cs-mr-25 cs-tm-10'>
            <div className='cs-fs-35 cs-hrz-center cs-fw-500'>
              Trending Products
            </div>
          </div>
        </Slide>

        <Fade triggerOnce>
          <div>
            <Row className='cs-hrz-center'>
              {CB_DATA.map((item, index) => {
                return <Col key={index} xs={24} xl={3} className='cs-hrz-center cs-tm-20'>
                  <CircleBtn img={item.src} title={item.title} />
                </Col>
              })}
            </Row>
          </div>

          <Row gutter={[20, 20]} className='cs-ml-30 cs-mr-30 cs-tm-40'>
            {fetchProductsData?.products?.filter((x) => x.is_popular).map((item, index) => {
              return <Col key={index} xs={12} sm={12} xl={4}>
                <ProductCard id={item.id} img={BASE_URL_ASSETS + item.images[0]} title={item.title} prices={item.sfp_units} mrp={item.mrp} />
              </Col>
            })}
          </Row>
        </Fade>

        <Row gutter={[15, 15]} className='cs-tm-40 cs-ml-30 cs-mr-30'>
          <Col xs={24} sm={24} xl={12} className='cs-image-container'>
            <img src={testi} className='cs-w-100' />
          </Col>
          <Col xs={24} sm={24} xl={12} className='cs-bg-grey'>
            <Testimonials data={TESTI_DATA} />
          </Col>
        </Row>

        <Slide triggerOnce direction='left'>
          <div className='cs-ml-25 cs-mr-25 cs-tm-40'>
            <div className='cs-dancing-font cs-fs-25 cs-hrz-center cs-fw-700'>
              Top Collections
            </div>
          </div>
        </Slide>

        <Slide triggerOnce direction='right'>
          <div className='cs-ml-25 cs-mr-25 cs-tm-10'>
            <div className='cs-fs-35 cs-hrz-center cs-fw-500'>
              Special Products
            </div>
          </div>
        </Slide>

        <Fade triggerOnce>
          <Row gutter={[20, 20]} className='cs-ml-30 cs-mr-30 cs-tm-40'>
            {fetchProductsData?.products?.filter((x) => x.is_special).map((item, index) => {
              return <Col key={index} xs={12} sm={12} xl={4}>
                <ProductCard id={item.id} img={BASE_URL_ASSETS + item.images[0]} title={item.title} prices={item.sfp_units} mrp={item.mrp} />
              </Col>
            })}
          </Row>
        </Fade>

        <Reveal triggerOnce>
          <div className='cs-ml-30 cs-mr-30 cs-tm-40'>
            <Services />
          </div>
        </Reveal>

        <Slide triggerOnce direction='left'>
          <div className='cs-ml-25 cs-mr-25 cs-tm-40'>
            <div className='cs-dancing-font cs-fs-25 cs-hrz-center cs-fw-700'>
              Recent Story
            </div>
          </div>
        </Slide>


        <div className='cs-ml-25 cs-mr-25 cs-tm-10'>
          <Slide triggerOnce direction='right'>
            <div className='cs-fs-35 cs-hrz-center cs-fw-500'>
              From The Blog
            </div>
          </Slide>

          <Fade triggerOnce>
            <BlogSlider data={fetchBlogData.blogs} />
          </Fade>
        </div>


      </div>
      : <div>
        <Spinner />
      </div>
  )
}

export default Home