import React from 'react';
import { Row, Col } from 'antd';
import footerImage from '../../static/assets/footer/footer_1.webp';

const Footer = () => {
  return (
    <div style={{
      backgroundImage: `url(${footerImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '100%',
      minHeight: '300px', // Changed height to minHeight to allow content to expand it
      padding: '40px 30px', // Added padding to create space between content and edges
    }}>
      <Row gutter={[20, 20]}>
        <Col xl={8}>
          <div className='cs-fs-20 cs-fw-700'>
            Our Newsletter
          </div>
          <div className='cs-fs-12'>
            Subscribe to get the best offers and designs for our website.
          </div>
          <input
            className='cs-tm-10'
            type="text"
            placeholder="Enter email"
            style={{
              padding: '6px',
              fontSize: '16px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              width: '300px', // Adjust width as needed
              outline: 'none', // Remove outline when focused
            }}
          />
        </Col>

        <Col xl={8}>
          <div className='cs-fs-20 cs-fw-700'>
            LOGO
          </div>
          <div className='cs-fs-12'>
            Spice up your life with flavors that ignite your senses.
          </div>

        </Col>

        <Col xl={8}>
          <div className='cs-fs-20 cs-fw-700'>
            Contact us:
          </div>
          <div className='cs-fs-12'>
            Call Us:+123-456-7898
          </div>
          <div className='cs-fs-12'>
            Address:Demo Store Demo Store USA
          </div>
          <div className='cs-fs-12'>
            Email ID: Support@gmail.com
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
