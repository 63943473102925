import React from 'react'
import Slider from "react-slick";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import './coverslider.css'

const CoverSlider = ({ data, title }) => {

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowLeftOutlined onClick={onClick} className="cs-left-arrow" />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowRightOutlined onClick={onClick} className="cs-right-arrow" />
    );
  }

  const SETTINGS = {
    // dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  return (
    <div>
      <Slider {...SETTINGS} className="slider-container cs-slider-container">
        {data.map((item, index) => {
          return <div id={index} className='' key={index}>
            <div className='cs-pos-rel'>
              <div className='cs-slider-cover-img-overlay'></div>
              <div className='cs-dancing-font cs-txt-cover-slider'>
                {title}
              </div>
              <div className='cs-dancing-font cs-bread-crumb-cover-slider'>
                <span>Home</span> / <span> {title} </span>
              </div>
              <img className="cs-slider-img" src={item.src} alt="" />
            </div>
          </div>
        })}
      </Slider>
    </div>
  )
}

export default CoverSlider