import React from 'react'
import { useNavigate } from "react-router-dom";

import './CircleBtn.css'

const CircleBtn = ({ img, title }) => {
  const navigate = useNavigate()

  const handleNavigation = (value) => {
    navigate(value)
  }
  return (
    <div>
      <div onClick={() => handleNavigation("/products")} className='cs-hrz-center cs-pointer'>
        <img src={img} className='cs-img-cb' alt="" />
      </div>
      <div className='cs-fw-600 cs-fs-16 cs-tm-5'>
        {title}
      </div>
    </div>
  )
}

export default CircleBtn