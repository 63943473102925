import React from 'react'
import { Row, Col } from 'antd'
import { Fade, Slide, Reveal } from "react-awesome-reveal";
import CoverSlider from '../../components/cover-slider/CoverSlider'
import pi1 from '../../static/assets/product-img/pi1.jpg'
import pi2 from '../../static/assets/product-img/pi2.jpg'
import pi3 from '../../static/assets/product-img/pi3.jpg'
import pi4 from '../../static/assets/product-img/pi4.jpg'
import pi5 from '../../static/assets/product-img/pi5.jpg'
import herbs from '../../static/assets/product-cover/herb.jpg'
import vegi from '../../static/assets/product-cover/vegetable.jpg'
import './product.css'
import ProductCard from '../../components/product-card/ProductCard'
import SideSlider from '../../components/side-slider/SideSlider'
import { useQuery } from 'react-query';
import { fetchProducts } from '../../services/product.service'
import { LongerCaching } from '../../common/config'
import { BASE_URL_ASSETS } from '../../common/server'
import Spinner from '../../components/spinner/Spinner';

const data = [{ src: herbs }, { src: vegi }]

const Products = () => {

  const PI_DATA = [{ src: pi1, sale: false, price: "78.00", mrp: "100.00", title: "Mirchi powder" },
  { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  { src: pi4, sale: false, price: "78.00", mrp: "100.00", title: "Product 4", },
  { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  { src: pi3, sale: true, price: "78.00", mrp: "100.00", title: "Product 3", },
  { src: pi4, sale: false, price: "78.00", mrp: "100.00", title: "Product 4", },
  { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  { src: pi5, sale: true, price: "78.00", mrp: "100.00", title: "Product 5", },
  { src: pi3, sale: true, price: "78.00", mrp: "100.00", title: "Product 3", },
  { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  { src: pi5, sale: true, price: "78.00", mrp: "100.00", title: "Product 5", },
  { src: pi3, sale: true, price: "78.00", mrp: "100.00", title: "Product 3", },
  ]

  /**
* @api_calls
*/

  // Define a query for rescue type data
  const { data: fetchProductsData, isLoading: fetchProductsLoading, isError: fetchProductsError, refetch: refetchfetchProducts } = useQuery(
    'fetchProductsData',
    () => fetchProducts(),
    {
      ...LongerCaching,
    }
  );

  return (
    !fetchProductsLoading ?
      <div>
        <Reveal triggerOnce>
          <CoverSlider data={data} title={"Products"} />
        </Reveal>
        {/* <Row>
        <Col xs={24} xl={24} className='cs-bg-grey'>
          <Row gutter={[10, 10]}>
            <Col xs={3} xl={3}>
            </Col>
            <Col xs={8} xl={8}>
              <div className='cs-fs-16 cs-p-10 cs-clr-sec cs-fw-500'>
                We have {PI_DATA.length} products
              </div>
            </Col>
          </Row>
        </Col>
      </Row> */}
        <div className='cs-lm-10 cs-rm-10'>
          <Row gutter={[20, 20]} className='cs-products-flex-direction'>
            <Col xl={6} className='cs-tm-40 cs-bg-grey'>
              <Slide direction='left' triggerOnce>
                <SideSlider products={fetchProductsData?.products} title={"Products"} />
              </Slide>
            </Col>

            <Col xl={18}>
              <Slide direction='right' triggerOnce>
                <Row gutter={[20, 20]} className='cs-tm-40'>
                  {fetchProductsData?.products?.map((item, index) => {
                    return <Col key={index} xs={12} sm={12} xl={6}>
                      <ProductCard id={item.id} img={BASE_URL_ASSETS + item.images[0]} title={item.title} prices={item.sfp_units} mrp={item.mrp} />
                    </Col>
                  })}
                </Row>
              </Slide>
            </Col>
          </Row>
        </div>

      </div>
      :
      <Spinner />
  )
}

export default Products