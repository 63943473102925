import React, { useState } from 'react';
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import mainLogo from '../../../static/assets/logo/logo.jpeg';
import { Dropdown, Space, Row, Col, Typography } from "antd";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { navItem } from '../common';
import { Outlet, useNavigate } from "react-router-dom";

const MobileNav = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (value) => {
    setExpanded(false); // Close the navbar when navigating
    navigate(value);
  }

  return (
    <Navbar expand="lg" className="bg-body-tertiary" expanded={expanded}>
      <Container>
        <Navbar.Brand onClick={() => handleNavigation("/")}>
          <img src={mainLogo} className='cs-logo-mobile' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <div className="cs-vt-center">
              <Typography.Text className="cs-pointer cs-rm-10" onClick={() => handleNavigation("/")}>Home</Typography.Text>
            </div>
          </Nav>
          <Nav className="me-auto">
            <div className="cs-vt-center">
              <Typography.Text className="cs-pointer cs-rm-10" onClick={() => handleNavigation("/products")}>Products</Typography.Text>
            </div>
          </Nav>
          <Nav className="me-auto">
            <div className="cs-vt-center">
              <Typography.Text className="cs-pointer cs-rm-10" onClick={() => handleNavigation("/about-us")}>About</Typography.Text>
            </div>
          </Nav>
          <Nav className="me-auto">
            <div className="cs-vt-center">
              <Typography.Text className="cs-pointer cs-rm-10">Contact</Typography.Text>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MobileNav;
