import React from "react";
import Slider from "react-slick";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import './slider.css'

const HomeSlider = ({ data }) => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowLeftOutlined onClick={onClick} className="cs-left-arrow" />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowRightOutlined onClick={onClick} className="cs-right-arrow" />
    );
  }

  const SETTINGS = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
  };

  return (
    <div>
      <Slider {...SETTINGS} className="slider-container cs-slider-container">
        {data.map((item, index) => {
          return <div id={index} key={index}>
            <div className="cs-pos-rel">
              <div className="cs-cover-img-overlay"></div>
              <img className="cs-slider-img" src={item.src} alt="" />
              <div className='cs-dancing-font cs-slider-title-txt'>
                {item.title}
              </div>
              <div className='cs-slider-sub-title-txt'>
                {item.subTitle}
              </div>
            </div>
          </div>
        })}
      </Slider>
    </div>
  )
}

export default HomeSlider