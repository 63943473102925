import React, { useState, useEffect } from 'react';
import { Row, Col, Select } from 'antd';
import { InstagramOutlined, FacebookOutlined, TwitterOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import './infoBar.css';
import { useQuery } from 'react-query';
import { fetchCurrency } from '../../services/currency.service';
import { useDispatch } from "react-redux";
import { LongerCaching } from '../../common/config';
import { setCurrency } from '../../redux/actions/currency';

const { Option } = Select;

const InfoBar = () => {
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const dispatch = useDispatch();

  /**
   * @api_calls
   */

  // Define a query for rescue type data
  const { data: fetchCurrencyData, isLoading: fetchCurrencyLoading, isError: fetchCurrencyError, refetch: refetchfetchCurrency } = useQuery(
    'fetchCurrencyData',
    () => fetchCurrency(),
    {
      ...LongerCaching,
    }
  );

  const handleCurrencyChange = (value) => {
    const selected = fetchCurrencyData?.currencies.find(currency => currency.id === parseInt(value));
    setSelectedCurrency(selected);
    dispatch(setCurrency(selected));
  };

  return (
    !fetchCurrencyLoading &&
    <div className='cs-of-hidden'>
      <Row gutter={[20, 20]} className='cs-info-bar-container'>
        <Col xs={24} xl={12}>
          <Row gutter={[10, 10]}>
            <Col xs={6} xl={4}>
              <div className='cs-social-info-bar-txt'>
                Follow on:
              </div>
            </Col>
            <Col xs={1} xl={1}>
              <InstagramOutlined className='cs-clr-white cs-pointer' />
            </Col>
            <Col xs={1} xl={1}>
              <FacebookOutlined className='cs-clr-white cs-pointer' />
            </Col>
            <Col xs={1} xl={1}>
              <TwitterOutlined className='cs-clr-white cs-pointer' />
            </Col>
            <Col xl={8}>
              {fetchCurrencyData?.currencies && (
                <Select className='cs-currency-dd' size='small' defaultValue={fetchCurrencyData?.currencies[1].id} style={{ width: 120 }} onChange={handleCurrencyChange}>
                  {fetchCurrencyData?.currencies.map(currency => (
                    <Option key={currency.id} value={currency.id}>{currency.name}</Option>
                  ))}
                </Select>
              )}
            </Col>
          </Row>
        </Col>

        <Col xs={24} xl={12}>
          <Row>
            <Col xl={8}>
            </Col>
            <Col xl={4}>
              <div className='cs-social-info-bar-txt cs-pointer cs-dis-flex'>
                <PhoneOutlined className='cs-clr-white cs-pointer cs-rm-5' />
                9509636507
              </div>
            </Col>
            <Col xl={1}>
              <div className='cs-social-info-bar-txt cs-pointer cs-dis-flex'>
                |
              </div>
            </Col>
            <Col xl={10}>
              <div className='cs-social-info-bar-txt cs-pointer cs-dis-flex'>
                <MailOutlined className='cs-clr-white cs-pointer cs-rm-5' />
                shyamfoodproducts@gmail.com
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default InfoBar;
