import React from 'react'
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
// import pi1 from '../../static/assets/product-img/pi1.jpg'
// import pi2 from '../../static/assets/product-img/pi2.jpg'
// import pi3 from '../../static/assets/product-img/pi3.jpg'
// import pi4 from '../../static/assets/product-img/pi4.jpg'
// import pi5 from '../../static/assets/product-img/pi5.jpg'
import Slider from "react-slick";
import './SideSlider.css'
import { BASE_URL_ASSETS } from '../../common/server';

const SideSlider = ({ products }) => {

  // const PI_DATA = [{ src: pi1, sale: false, price: "78.00", mrp: "100.00", title: "Mirchi powder" },
  // { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  // { src: pi4, sale: false, price: "78.00", mrp: "100.00", title: "Product 4", },
  // { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  // { src: pi3, sale: true, price: "78.00", mrp: "100.00", title: "Product 3", },
  // { src: pi4, sale: false, price: "78.00", mrp: "100.00", title: "Product 4", },
  // { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  // { src: pi5, sale: true, price: "78.00", mrp: "100.00", title: "Product 5", },
  // { src: pi3, sale: true, price: "78.00", mrp: "100.00", title: "Product 3", },
  // { src: pi2, sale: true, price: "78.00", mrp: "100.00", title: "Product 2", },
  // { src: pi5, sale: true, price: "78.00", mrp: "100.00", title: "Product 5", },
  // { src: pi3, sale: true, price: "78.00", mrp: "100.00", title: "Product 3", },
  // ]


  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowLeftOutlined onClick={onClick} className="cs-left-arrow" />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowRightOutlined onClick={onClick} className="cs-right-arrow" />
    );
  }

  const SETTINGS = {
    // dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div>
      <div className='cs-tm-20 cs-bm-20'>
        <div className='cs-fs-30 cs-dancing-font cs-fw-600 cs-hrz-center'>
          Discover
        </div>
      </div>
      <Slider {...SETTINGS} className="slider-container cs-slider-container">
        {products?.map((item, index) => {
          return <div id={index} className='' key={index}>
            <div className='cs-pos-rel'>
              <img className="cs-slider-img" src={BASE_URL_ASSETS + item.images[0]} alt="" />
              <div className='cs-fs-20 cs-fw-600 cs-hrz-center'>
                {item.title}
              </div>
            </div>
          </div>
        })}
      </Slider>
      <div className='cs-fs-20 txt-align-center cs-fw-600 cs-tm-40 cs-dancing-font cs-hrz-center'>
        Discover our selection of popular spices, carefully curated to elevate your culinary creations. From timeless classics to exotic blends, our popular products cater to every taste and cooking style. Explore our top-selling spices and add a dash of flavor to your dishes that will leave your taste buds craving more.
      </div>
    </div>
  )
}

export default SideSlider