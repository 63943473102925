import React from 'react'
import Slider from "react-slick";
import { Row, Col } from 'antd'
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { BASE_URL_ASSETS } from '../../common/server';
import './productSingle.css'

const ImageSlider = ({ images }) => {

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowLeftOutlined onClick={onClick} className="cs-left-arrow" />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowRightOutlined onClick={onClick} className="cs-right-arrow" />
    );
  }

  const SETTINGS = {
    // dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    // waitForAnimate: false,
    // fade: true,
    customPaging: function (i) {
      return (
        <img className="cs-slider-thumbnail" src={BASE_URL_ASSETS + images[i]} alt="" />
      );
    },
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
  };

  return (
    <div>
      <Slider {...SETTINGS} className="slider-container cs-slider-container">
        {images?.map((item, index) => {
          return <div id={index} className='' key={index}>
            <div className='cs-pos-rel'>
              <img className="cs-slider-img" src={BASE_URL_ASSETS + item} alt="" />
              <div className='cs-fs-20 cs-fw-600 cs-hrz-center'>
                {item.title}
              </div>
            </div>
          </div>
        })}
      </Slider>
    </div>
  )
}

export default ImageSlider