import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Typography, Tag, Divider, Rate } from 'antd';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchProducts } from '../../services/product.service';
import { LongerCaching } from '../../common/config';
import { BASE_URL_ASSETS } from '../../common/server';
import ImageSlider from './ImageSlider';
import { useSelector } from "react-redux";
import ProductCard from '../../components/product-card/ProductCard';

const { Text, Title } = Typography;

const defaultCurrency = { symbol: "Rs:", price: 1 }

const ProductSingle = () => {
  const { id } = useParams();
  const selectedCurrency = useSelector((state) => state.currency.currency ? state.currency.currency : defaultCurrency);

  const [singleProductData, setSingleProductData] = useState(undefined);
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');

  // Shuffle array function
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Define a query for product data
  const { data: fetchProductsData, isLoading: fetchProductsLoading } = useQuery(
    'fetchProductsData',
    () => fetchProducts(),
    {
      ...LongerCaching,
    }
  );

  useEffect(() => {
    if (!fetchProductsLoading) {
      // Find the product with the given id from the fetched data
      const product = fetchProductsData?.products.find((x) => x.id === +id);
      setSingleProductData(product);

      // Set the default selected unit to the 0th index unit
      if (product && product.sfp_units.length > 0) {
        setSelectedUnit(product.sfp_units[0].unit);
        setSelectedPrice(product.sfp_units[0].price);
      }
    }
  }, [fetchProductsLoading, +id]);

  // Function to handle unit selection
  const handleUnitSelection = (unit, price) => {
    setSelectedUnit(unit);
    setSelectedPrice(price);
  };

  return (
    <div className='cs-ml-30 cs-mr-30 cs-tm-40 main-product-page'>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} xl={8}>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} xl={24}>
              {/* Display image slider */}
              <ImageSlider images={singleProductData?.images} />
            </Col>
          </Row>
        </Col>

        <Col xl={14}>
          <Row gutter={[20, 20]}>
            <Col xl={24}>
              {/* Display product title */}
              <Typography.Title level={3}>{singleProductData?.title}</Typography.Title>
              <div className='cs-tm-10 cs-mb-10'>
                <Rate value={3.9 + Math.random() * (4.7 - 3.9)} disabled allowHalf />
              </div>
              {/* Display product description */}
              <Text>{singleProductData?.description}</Text>
            </Col>
            {/* Display units and prices */}
            <Divider dashed />
            <Col xl={24}>
              <Title level={5}>
                Select Size
              </Title>
              <div className='cs-tm-10'>
                {singleProductData?.sfp_units.map((unit) => (
                  <Tag
                    className='cs-pointer'
                    key={unit.id}
                    color={selectedUnit === unit.unit ? 'geekblue' : 'default'}
                    onClick={() => handleUnitSelection(unit.unit, unit.price)}
                  >
                    {unit.unit} - {unit.price}
                  </Tag>
                ))}
              </div>
              <div className='cs-tm-30'>
                <Title level={4}>{selectedCurrency.symbol} {selectedPrice / (+selectedCurrency.price)}</Title>
              </div>
              <Divider />
              {/* Display selected price */}
            </Col>
          </Row>
        </Col>
      </Row>

      {fetchProductsData?.products?.length
        && <div className='cs-ml-30 cs-mr-30 cs-tm-40 cs-mb-10'>
          <Title level={3}>
            Related Products
          </Title>

          <Row gutter={[20, 20]}>
            {shuffleArray(fetchProductsData.products).slice(0, 6).map((item, index) => (
              <Col key={index} xs={12} sm={12} xl={4}>
                <ProductCard id={item.id} img={BASE_URL_ASSETS + item.images[0]} title={item.title} prices={item.sfp_units} mrp={item.mrp} />
              </Col>
            ))}
          </Row>
        </div>}
    </div>
  );
};

export default ProductSingle;
