import { Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import Home from "./pages/home/Home";
import LayoutWrapper from "./layout";
import "bootstrap/dist/css/bootstrap.min.css";
import "./static/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "antd/dist/reset.css";
import Products from "./pages/products/Products";
import ProductSingle from "./pages/product-single/ProductSingle";
import { useEffect } from "react";
import ScrollToTop from "./ScrollToTop";
import AboutUs from "./pages/about-us/AboutUs";
import BlogDetail from "./components/blog-detail/BlogDetail";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="cs-of-hidden">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#ffb12a",
            borderRadius: 0,
          },
        }}
      >
        <Routes>
          <Route path="/" element={<LayoutWrapper />}>
            <Route path="/" index element={<Home />} />
            <Route path="/products" index element={<Products />} />
            <Route path="/product/:id" index element={<ProductSingle />} />
            <Route path="/about-us" index element={<AboutUs />} />
            <Route path="/blog-details" index element={<BlogDetail />} />
          </Route>
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default App;
