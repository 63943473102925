import React from 'react'
import { Row, Col } from 'antd'
import herbs from '../../static/assets/product-cover/herb.jpg'
import abt2 from '../../static/assets/about-us-cover/abo-2_2048x.webp'
import abt3 from '../../static/assets/about-us-cover/abo-3_2048x.webp'
import vegi from '../../static/assets/product-cover/vegetable.jpg'
import abtus from '../../static/assets/about-us-cover/abo-1.jpg'
import CoverSlider from '../../components/cover-slider/CoverSlider'
import Testimonials from '../../components/testimonials/Testimonials'
import testi from '../../static/assets/static-img/testi_1370x385.jpg'
import testi1 from '../../static/assets/static-img/image_1_303x.avif'
import testi2 from '../../static/assets/static-img/image_2_303x.avif'

const data = [{ src: herbs }, { src: vegi }]

const TESTI_DATA = [
  { src: testi1, name: "Mark embrok", review: "Absolutely blown away by the quality and flavor of the spices from [Spice Brand]. As a passionate home cook, I've tried numerous spice brands, but none come close to the richness and freshness I found in these products. From the vibrant color to the intense aroma, every spice I've used has elevated my dishes to a whole new level. My kitchen feels incomplete without [Spice Brand] spices now. Highly recommended!" },
  { src: testi2, name: "Jack", review: "I stumbled upon [Spice Brand] while searching for high-quality spices online, and I'm so glad I did! The moment I opened the package, I could tell these were no ordinary spices. The packaging itself exuded freshness and authenticity. But it was the taste that truly amazed me. Each spice blend is perfectly balanced, adding depth and complexity to my cooking. Whether it's a simple weekday meal or a special occasion feast, [Spice Brand] spices have become my secret ingredient for creating unforgettable dishes. Thank you for bringing such exceptional flavors into my kitchen!" }
]


const AboutUs = () => {
  return (
    <div>
      <CoverSlider data={data} title={"About us"} />

      <div className='cs-ml-25 cs-mr-25 cs-tm-40'>
        <Row gutter={[20, 20]}>
          <Col xs={24} xl={12}>
            <div className='cs-dancing-font cs-fs-30 cs-fw-700'>
              The Richest Masala In The World
            </div>
            <div className='cs-fs-16'>
              Embark on a sensory journey like no other with 'The Richest Masala In The World'. Our meticulously curated collection of masala blends embodies the essence of culinary excellence, meticulously crafted from the most exquisite spices sourced from across the globe. Each blend is a testament to centuries-old traditions, capturing the essence of diverse cultures and regional flavors.
              <br />
              From the fiery depths of Indian spices to the aromatic allure of Middle Eastern blends, our masalas are a celebration of authenticity and richness. With every sprinkle and dash, you'll be transported to faraway lands, indulging in the vibrant tapestry of flavors that our masalas offer.
            </div>
          </Col>

          <Col xs={24} xl={8}>
            <img src={abtus} className='cs-slider-img' alt="" />
          </Col>
        </Row>
      </div>

      <div className='cs-dancing-font cs-fs-40 cs-hrz-center cs-tm-40 cs-fw-700'>
        A unique blended taste
      </div>

      <div className='cs-ml-25 cs-mr-25 cs-tm-40'>
        <Row gutter={[20, 20]}>
          <Col xs={24} xl={4}>
          </Col>

          <Col xs={24} xl={8}>
            <img src={abt2} className='cs-slider-img' alt="" />
          </Col>

          <Col xs={24} xl={9}>
            <div className='cs-dancing-font cs-fs-30 cs-fw-700'>
              The finest spice
            </div>
            <div className='cs-fs-16'>
              Crafted with meticulous care and sourced from the world's best regions, our finest spices offer unmatched quality, flavor, and versatility, ensuring every culinary creation shines with unparalleled excellence.
              Indulge in the epitome of culinary excellence with our finest spice selection. Handpicked for their premium quality, exquisite flavor, and rich aroma, our spices elevate every dish to perfection
            </div>
          </Col>

          <Col xs={24} xl={4}>
          </Col>

          <Col xs={24} xl={9}>
            <div className='cs-dancing-font cs-fs-30 cs-fw-700 cs-flex-end'>
              The premium flavor
            </div>
            <div className='cs-fs-16'>
              Experience the pinnacle of taste with our premium flavor offerings. Hand-selected for their superior quality and depth, our spices promise to enrich every dish with an unforgettable burst of exquisite flavor.
              Unlock a world of culinary delight with our premium flavor spices. Carefully curated and expertly crafted, each spice in our collection delivers a sensory journey like no other, infusing your dishes with unmatched richness and complexity, elevating them to gourmet heights.
            </div>
          </Col>

          <Col xs={24} xl={8}>
            <img src={abt3} className='cs-slider-img' alt="" />
          </Col>

        </Row>
      </div>

      <Row gutter={[15, 15]} className='cs-tm-40 cs-ml-30 cs-mr-30'>
        <Col xs={24} sm={24} xl={12} className='cs-image-container'>
          <div className='cs-dancing-font cs-fs-30 cs-fw-700'>
            Look what our customers says
          </div>

          <div className='cs-fs-16'>
            1. <b>Exceptional Quality</b> Customers rave about the exceptional quality of our products, emphasizing the freshness and richness of our spices. <br />

            2. <b>Unmatched Flavor</b> They praise the unmatched flavor profiles of our masalas, noting the depth and complexity they add to their dishes.<br />

            3. <b>Authenticity</b> Many customers appreciate the authenticity of our blends, stating that they capture the true essence of traditional spice mixes from various cultures.<br />

            4. <b>Versatility</b> Our customers love the versatility of our masalas, mentioning how they can be used in a wide range of recipes, from classic Indian curries to fusion cuisine.<br />

            5. <b>Customer Service</b> Positive feedback extends beyond the products to our customer service, with clients highlighting our prompt responses and helpful assistance.<br />

            6. <b>Value for Money</b> Customers often mention that our products offer great value for money, considering the premium quality and quantity provided.<br />

            7. <b>Repeat Purchases</b> The fact that many customers are repeat buyers speaks volumes about their satisfaction and loyalty to our brand.<br />

            8. <b>Recommendations</b> Satisfied customers frequently recommend our spices to friends and family, further validating the excellence of our products.<br />
          </div>
        </Col>
        <Col xs={24} sm={24} xl={12} className='cs-bg-grey'>
          <Testimonials data={TESTI_DATA} />
        </Col>
      </Row>
    </div>
  )
}

export default AboutUs