import React from 'react'
import { Row, Col, Divider } from 'antd'
import s1 from '../../static/assets/services-img/image_1_5ca7afdb-8663-4a06-8011-5df3f24f7c2f.png'
import s2 from '../../static/assets/services-img/image_2_a6465070-2dc8-44e5-b1e2-ac5d07628475.webp'
import s3 from '../../static/assets/services-img/image_3_fc0c4166-58d8-4375-b273-7d71884a8878.webp'
import s4 from '../../static/assets/services-img/image_4.png'

const Services = () => {
  return (
    <div>
      <Divider />
      <Row>
        <Col className='cs-tm-40-in-mob' xl={6}>
          <Row gutter={[20, 20]}>
            <Col xl={4}>
            </Col>
            <Col xl={4} className='cs-vt-center'>
              <img src={s1} className='cs-w-100' alt="" />
            </Col>
            <Col xl={16}>
              <div className='cs-fw-600 cs-fs-20'>
                International Shipping
              </div>

              <div className='cs-fs-12'>
                Shipping World Wide
              </div>
            </Col>
          </Row>
        </Col>

        <Col className='cs-tm-40-in-mob' xl={6}>
          <Row gutter={[20, 20]}>
            <Col xl={4}>
            </Col>
            <Col xl={4} className='cs-vt-center'>
              <img src={s2} className='cs-w-100' alt="" />
            </Col>
            <Col xl={16}>
              <div className='cs-fw-600 cs-fs-20'>
                24 X 7 Service
              </div>

              <div className='cs-fs-12'>
                24 X 7 care support
              </div>
            </Col>
          </Row>
        </Col>

        <Col className='cs-tm-40-in-mob' xl={6}>
          <Row gutter={[20, 20]}>
            <Col xl={4}>
            </Col>
            <Col xl={4} className='cs-vt-center'>
              <img src={s4} className='cs-w-100' alt="" />
            </Col>
            <Col xl={16}>
              <div className='cs-fw-600 cs-fs-20'>
                Festival Offer
              </div>

              <div className='cs-fs-12'>
                Best Offers Available
              </div>
            </Col>
          </Row>
        </Col>

        <Col className='cs-tm-40-in-mob' xl={6}>
          <Row gutter={[20, 20]}>
            <Col xl={4}>
            </Col>
            <Col xl={4} className='cs-vt-center'>
              <img src={s3} className='cs-w-100' alt="" />
            </Col>
            <Col xl={16}>
              <div className='cs-fw-600 cs-fs-20'>
                Best Price
              </div>

              <div className='cs-fs-12'>
                Offering Best Price In Market
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />

    </div>
  )
}

export default Services