import React from 'react'
import Slider from "react-slick";
import { Row, Col } from 'antd'
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import c1 from '../../static/assets/static-img/image_1_303x.avif'
import './testimonials.css'

const Testimonials = ({ data }) => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowLeftOutlined onClick={onClick} className="cs-left-arrow" />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowRightOutlined onClick={onClick} className="cs-right-arrow" />
    );
  }

  const SETTINGS = {
    dots: true,
    infinite: true,
    auto: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className='cs-tm-20'>
      <Slider {...SETTINGS} className="slider-container cs-slider-container">
        {data.map((item, index) => {
          return <div key={index}>
            <Row className='cs-hrz-center'>
              <Col xl={6}>
                <img src={item.src} className='cs-w-100 cs-testi-img' alt="" />
                <div className='cs-fw-700 cs-hrz-center cs-tm-20 cs-fs-20'>
                  {item.name}
                </div>
              </Col>

              <Col xl={24}>
                <div className='cs-dancing-font cs-fw-700 cs-hrz-center cs-tm-20 cs-fs-20 txt-align-center'>
                  {item.review}
                </div>
              </Col>
            </Row>

          </div>
        })}
        {/* {data.map((item, index) => {
          return <div id={index} key={index}>
            <img className="cs-w-100" src={item.src} alt="" />
          </div>
        })} */}
      </Slider>
    </div>
  )
}

export default Testimonials