import React from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import { Layout, Menu, Row, Col, Typography } from 'antd';
import mainLogo from '../../../static/assets/logo/logo.jpeg';
import { navItem } from '../common';
import InfoBar from '../../infoBar/InfoBar';

const { Header, Content, Footer, Sider } = Layout;

const WebNav = () => {
  const navigate = useNavigate()

  const handleNavigation = (value) => {
    navigate(value)
  }
  return (
    <Layout>
      <Header
        className='cs-custom-header'
        style={{
          padding: 0,
          background: "#fff",

        }}
      >
        <Row>
          <Col xs={1}>
          </Col>
          <Col xs={3} className='cs-vt-center cs-pointer' onClick={() => handleNavigation("/")}>
            <img src={mainLogo} className='cs-desktop-logo' />
          </Col>
          <Col xs={19}>
            <div className='cs-nav-grid-container'>

              <div className='cs-txt-nav-header cs-pointer' onClick={() => handleNavigation("/")}>
                Home
              </div>

              <div className='cs-txt-nav-header cs-pointer' onClick={() => handleNavigation("/products")}>
                Products
              </div>

              <div className='cs-txt-nav-header cs-pointer' onClick={() => handleNavigation("/about-us")}>
                About
              </div>

              <div className='cs-txt-nav-header cs-pointer'>
                Contact
              </div>
            </div>
          </Col>
          <Col xs={1}>
          </Col>
        </Row>
      </Header>
      {/* <Footer
        style={{
          textAlign: 'center',
        }}
      >
        Ant Design ©{new Date().getFullYear()} Created by Ant UED
      </Footer> */}
    </Layout>
  )
}

export default WebNav