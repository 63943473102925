import React from 'react'
import { Button } from 'antd'
import { useNavigate } from "react-router-dom";
import './featureCard.css'

const FeatureCard = ({ image, title, btnTxt, featureTxt }) => {
  const navigate = useNavigate()

  const handleNavigation = (value) => {
    navigate(value)
  }
  return (
    <div className='cs-fc-image-container'>
      <div className='cs-title-txt'>
        {title}
      </div>
      <div className='cs-feature-txt cs-dancing-font'>
        {featureTxt}
      </div>
      <Button onClick={() => handleNavigation("/products")} size='large' type='primary' className='cs-sn-btn'>
        Show now
      </Button>
      <img className='cs-w-100' src={image} alt="feature-card" />
    </div>
  )
}

export default FeatureCard