import React from "react";
import Slider from "react-slick";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import BlogCards from "../blog-cards/BlogCards";
import { BASE_URL_ASSETS } from "../../common/server";

const BlogSlider = ({ data }) => {
  console.log("blogs", data)
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowLeftOutlined onClick={onClick} className="cs-left-arrow" />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowRightOutlined onClick={onClick} className="cs-right-arrow" />
    );
  }

  const SETTINGS = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <Slider {...SETTINGS} className="slider-container cs-slider-container">
        {data.map((item, index) => {
          return <div className="cs-blog-container" id={index} key={index}>
            <BlogCards image={BASE_URL_ASSETS + item.image[0]} title={item.title} createdAt={item.createdAt} createdBy={item.created_by} />
          </div>
        })}
      </Slider>
    </div>
  )
}

export default BlogSlider