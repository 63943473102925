import React from 'react'
import { Button, Card } from 'antd'
import './blogCard.css'

const BlogCards = ({ image, title, createdBy, createdAt }) => {
  return (
    <div className='cs-pointer'>
      <div className='cs-blgc-image-container'>
        <div className='cs-blog-card-title-txt'>
          {new Date(createdAt).getDate()} | {new Date(createdAt).toLocaleString('default', { month: 'long' })}
        </div>
        <img className='cs-w-100' src={image} alt="feature-card" />
      </div>
      <Card>
        <div className='cs-fs-20 cs-fw-600'>
          {title}
        </div>

        <div className='cs-fs-16 cs-fw-400'>
          By: {createdBy}, {Math.ceil(300 + Math.random() * (900 - 300))} views
        </div>
      </Card>
    </div>
  )
}

export default BlogCards