import * as actionTypes from "../actions/actionTypes";

const initalState = {
  currency: undefined,
};

export const currency = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.setCurrency:
      return { currency: action.payload };

    default:
      return state;
  }
};
