import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Dropdown, Space, Row, Col, Typography } from "antd";
import MobileNav from "../components/navbar/mobile/MobileNav";
import WebNav from "../components/navbar/web/WebNav";
import InfoBar from "../components/infoBar/InfoBar";
import Footer from "../components/footer/Footer";

const LayoutWrapper = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="cs-content cs-show-mobile">
        <MobileNav />
      </div>
      <div className="cs-content cs-show-desktop">
        <WebNav />
      </div>
      <InfoBar />
      <div>
        <Outlet />
      </div>
      <div className="cs-tm-40">
        <Footer />
      </div>
    </div>
  );
};
export default LayoutWrapper;
